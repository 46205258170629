.slide {
  width: 100%;
  height: auto;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
}

.slide img {
  width: 700px;
  height: 80%;
  object-fit: cover;
}
.active-anim {
  opacity: 1;
}

.btn-slide {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #f1f1f1;
  border: 1px solid #CEE002;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-slide img {
  width: 25px;
  height: 25px;
  pointer-events: none;
}
.prev {
  top: 43%;
  left: 83px;
  transform: translateY(-60%);
}
.next {
  top: 43%;
  right: 68px;
  transform: translateY(-60%);
}



@media screen and (min-width: 100px) and (max-width: 768px){
  .slide {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    margin-left: 0;
    transition: opacity ease-in-out 0.4s;
  }
  .slide img {
    width: 100%;
    height: 80%;
    object-fit: cover;
  }
  .active-anim {
    opacity: 1;
  }
  
  .btn-slide {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #f1f1f1;
    border: 1px solid #CEE002;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .btn-slide img {
    width: 25px;
    height: 25px;
    pointer-events: none;
  }
  .prev {
    top: 50%;
    left: 20px;
    transform: translateY(-60%);
  }
  .next {
    top: 50%;
    right: 20px;
    transform: translateY(-60%);
  }

}